import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiLinearChart } from '../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uilinearchart"
    }}>{`UiLinearChart`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/charts/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Linear chart that represents the percentage of a current value inside a limit value.`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation-️"
    }}>{`Installation ⚙️`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/flex @uireact/charts`}</p>
    </blockquote>
    <h2 {...{
      "id": "uilinearchart-"
    }}>{`UiLinearChart 📊`}</h2>
    <p>{`This component takes 2 values: `}<inlineCode parentName="p">{`currentValue`}</inlineCode>{` and `}<inlineCode parentName="p">{`limitValue`}</inlineCode>{`, the `}<inlineCode parentName="p">{`limitValue`}</inlineCode>{` is the 100% of the chart and the current value represents how much of the `}<inlineCode parentName="p">{`limitValue`}</inlineCode>{` is covered, if the current value is bigger than the limit then it
will default to 100%.`}</p>
    <Playground __position={1} __code={'<UiLinearChart\n  data={{\n    current: {\n      value: 15,\n    },\n    limit: {\n      value: 30,\n    },\n    title: \'Demo\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiLinearChart,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiLinearChart data={{
        current: {
          value: 15
        },
        limit: {
          value: 30
        },
        title: 'Demo'
      }} mdxType="UiLinearChart" />
    </Playground>
    <h2 {...{
      "id": "uilinearchart---with-labels"
    }}>{`UiLinearChart - with labels`}</h2>
    <p>{`The limit label appears at the chart's top right corner, the current label renders underneath the current edge by default.`}</p>
    <Playground __position={2} __code={'<UiLinearChart\n  data={{\n    current: {\n      label: \'35,000 pts used\',\n      value: 35000,\n    },\n    limit: {\n      label: \'50,000 pts\',\n      value: 50000,\n    },\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiLinearChart,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiLinearChart data={{
        current: {
          label: '35,000 pts used',
          value: 35000
        },
        limit: {
          label: '50,000 pts',
          value: 50000
        }
      }} mdxType="UiLinearChart" />
    </Playground>
    <h2 {...{
      "id": "uilinearchart---with-static-label-position"
    }}>{`UiLinearChart - with static label position`}</h2>
    <p>{`When static label is used the label for the current value will be static at the bottom of the chart`}</p>
    <Playground __position={3} __code={'<UiLinearChart\n  data={{\n    current: {\n      label: \'300 lbs\',\n      value: 300,\n      labelStatic: true,\n    },\n    limit: {\n      label: \'1000 lbs\',\n      value: 1000,\n    },\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiLinearChart,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiLinearChart data={{
        current: {
          label: '300 lbs',
          value: 300,
          labelStatic: true
        },
        limit: {
          label: '1000 lbs',
          value: 1000
        }
      }} mdxType="UiLinearChart" />
    </Playground>
    <h2 {...{
      "id": "uilinearchart---with-theme-colors"
    }}>{`UiLinearChart - with theme colors`}</h2>
    <p>{`We can use the color categories for grabbing a specific theme color for the chart`}</p>
    <Playground __position={4} __code={'<UiLinearChart\n  data={{\n    current: {\n      color: \'error\',\n      label: \'60%\',\n      value: 60,\n      labelStatic: true,\n    },\n    limit: {\n      color: \'positive\',\n      label: \'80% battery\',\n      value: 80,\n    },\n    title: \'Demo with color\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiLinearChart,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiLinearChart data={{
        current: {
          color: 'error',
          label: '60%',
          value: 60,
          labelStatic: true
        },
        limit: {
          color: 'positive',
          label: '80% battery',
          value: 80
        },
        title: 'Demo with color'
      }} mdxType="UiLinearChart" />
    </Playground>
    <h2 {...{
      "id": "uilinearchart---with-custom-colors"
    }}>{`UiLinearChart - with custom colors`}</h2>
    <p>{`The custom colors supports hex and rgb colors`}</p>
    <Playground __position={5} __code={'<UiLinearChart\n  data={{\n    current: {\n      color: \'rgb( 28, 225, 127)\',\n      label: \'60%\',\n      value: 60,\n      labelStatic: true,\n    },\n    limit: {\n      color: \'#BE3708\',\n      label: \'80% battery\',\n      value: 80,\n    },\n    title: \'Demo with color\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiLinearChart,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiLinearChart data={{
        current: {
          color: 'rgb( 28, 225, 127)',
          label: '60%',
          value: 60,
          labelStatic: true
        },
        limit: {
          color: '#BE3708',
          label: '80% battery',
          value: 80
        },
        title: 'Demo with color'
      }} mdxType="UiLinearChart" />
    </Playground>
    <h3 {...{
      "id": "uilinearchart---props"
    }}>{`UiLinearChart - Props`}</h3>
    <Props of={UiLinearChart} mdxType="Props" />
    <p>{`Definiton of `}<inlineCode parentName="p">{`UiLinearChartData`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`type UiLinearChartData = {
  title?: string;
  limit: {
    color?: string | ColorCategory;
    label?: string;
    value: number;
    labelStatic?: boolean;
  };
  current: {
    color?: string | ColorCategory;
    label?: string;
    value: number;
  };
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      